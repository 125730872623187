@import 'colors';
@import '_text';

:local {
  input[type='text'].textField {
    @extend %text;
    box-shadow: none;
    margin: 0 0 0.5em;
    border-radius: 0;
    border-color: $grey-400;
    height: 32px;
  }

  input:disabled {
    background-color: rgba($blue-900, 0.1);
    color: $color-text-mid;
  }

  input[type='text'].smallSize {
    height: 24px;
  }

  input[type='text'].largeSize {
    height: 40px;
  }
}
