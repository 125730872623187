@import 'colors';

.admin-settings-row-group {
  padding: 8px 12px;
  border-bottom: 1px solid $tepid-sloth;

  &--flush {
    padding-left: 0;
    padding-right: 0;
  }

  &--borderless {
    border-bottom: none;
  }
}
