@import 'scss/colors.scss';
@import 'scss/module/settings';

.api-section-right {
  margin-left: auto !important;
}

.section-header {
  display: flex !important;
}

#deleteAccountButtonSection {
  top: auto;
}

#deleteAccountPopup {
  height: 450px !important;
}

:local {
  .didYouKnowAlert {
    margin-bottom: var(--spacing__unit--2);
  }
  .quickStartLink {
    text-decoration: none;
  }
  .generateApiKeyButton.generateApiKeyButton.generateApiKeyButton {
    // increase specificity to override styles
    color: var(--dig-color__text__base);
  }

  // Container for snackbar that covers the full page (non-browse)
  // TODO: this should better reside in override.scss but due to other issue keeping it here for now
  .digSnackbarCustom {
    position: fixed;
    display: flex;
    z-index: 1;
    left: 50%;
    justify-content: center;
    bottom: 30px;

    pointer-events: none;
    * {
      pointer-events: auto;
    }

    > * {
      flex-grow: 1;
    }
  }

  .apiWhitespace {
    white-space: pre;
  }

  .apiIconButton {
    vertical-align: middle;
    margin: 0 4px;
  }

  .apiCopyIconButton {
    vertical-align: middle;
    margin: 0 4px 4px;
  }

  .apiSettingOrderList {
    list-style: auto;
    padding: 0 0 0 16px;
  }

  .apiApps {
    float: left;
    width: 100%;

    section {
      margin-top: 24px;
      display: flex;

      & > *:not(:first-child):last-child {
        margin-left: auto;
      }

      &:first-child {
        margin-top: 16px;
        align-items: flex-end;
      }

      ul {
        li {
          color: var(--dig-color__text__base);
          margin-bottom: 8px;
          line-height: 18px;

          &:last-child {
            margin-bottom: 0;
          }

          .domain {
            color: var(--dig-color__primary__base);
          }

          .status {
            pointer-events: none;
          }
        }
      }
    }

    .apiApp {
      button {
        margin: 0;
      }

      .actions {
        button {
          margin-left: 12px;
        }
      }
      .marginBadge {
        margin-right: var(--spacing__base_unit);
        margin-bottom: var(--spacing__base_unit);
      }
    }
  }
}

.m-settings {
  .m-settings--content {
    #api-settings {
      .m-settings--section {
        border-top: none;
        border-bottom: 1px solid $grey-100;
        padding: 16px 0;

        .m-settings--section--header {
          margin: 8px 0;
        }
        .m-settings--api--account-delete-fax-checkbox {
          display: flex;

          .checkbox-label {
            padding-left: 15px;
          }
        }

        .m-settings--api--account-callback {
          display: flex;
          float: left;
          width: 100%;

          p {
            width: 286px;
            margin-right: 8px;
          }

          button:last-child {
            margin-left: auto;
          }
        }

        .m-settings--callback-report--success {
          color: var(--dig-color__success__base);
        }

        .m-settings--callback-report--failed {
          color: var(--dig-color__alert__base);
        }
      }
    }
  }
}
