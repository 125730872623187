.description {
  margin-top: var(--spacing__unit--1);
}

.heroImage {
  background: var(--dig-color__background__subtle);
}

.pauseDurationContainer {
  display: flex;
  align-items: center;
}

.selector {
  width: 160px;
}
