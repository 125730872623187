@import 'colors';

.admin-settings-dropdown {
}

.admin-settings-dropdown__label {
  padding-bottom: 4px;
  font-weight: 300;
  font-size: 14px;
  color: $castle-rock;
}

.admin-settings-dropdown__select {
  font-size: 14px;
  border-radius: 2px;
  background-color: transparent;
  margin: 0;
  height: 2.3125rem;
  padding: 5px 20px 5px 6px;
  line-height: 26px;
  margin-top: -1px;

  // Increase specificity to override legacy global styles
  .admin-settings-dropdown__select {
    margin: 0;
    box-shadow: none;

    &:focus,
    &:active {
      box-shadow: none;
      border-radius: 2px;
      background-color: transparent;
    }
  }

  &:disabled {
    background: $warm-chinchilla;
    cursor: not-allowed;
  }
}

.admin-settings-dropdown__notice {
  padding-top: 4px;
  font-size: 12px;
}
