.embedded-testing-tool__code {
  float: right;
  width: 37.5%;
  box-sizing: border-box;
  position: sticky;
  top: 10px;
  margin-top: 44px;
  opacity: 1;

  @media screen and (max-width: 1000px) {
    display: none;
  }
}

.embedded-testing-tool__code-example {
  padding: 0 !important;
  font-size: 11px !important;
  font-family:
    Menlo,
    Bitstream Vera Sans Mono,
    DejaVu Sans Mono,
    Monaco,
    Consolas,
    monospace !important;
}

.embedded-testing-tool__code-example * {
  font-size: 11px !important;
  font-family:
    Menlo,
    Bitstream Vera Sans Mono,
    DejaVu Sans Mono,
    Monaco,
    Consolas,
    monospace !important;
}

.embedded-testing-tool__code button {
  margin: 0;
  margin-top: 10px;
  float: right;
}
