@import 'colors';

.disclosure-modal-close-button {
  cursor: pointer;
  height: 12px;
  position: absolute;
  right: 12px;
  top: 12px;
  width: 12px;
}

.disclosure-modal-header {
  background: $plume;
  border-bottom: 1px solid $light-grey;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 2px 2px -2px $grey;
}

.disclosure-modal-footer {
  background: $plume;
  border-radius: 0 0 5px 5px;
  border-top: 1px solid $light-grey;
  box-shadow: 0 -2px 2px -2px $grey;

  & a {
    margin-right: 18px;

    & img {
      height: 24px;
      width: 24px;
    }
  }
}

.disclosure-modal-content {
  color: $ashen;
  font-size: 14px;
  font-weight: 300;
  max-height: 45vh;
  overflow-y: scroll;

  & ul,
  p {
    color: inherit;
    font-size: inherit;

    & li {
      color: inherit;
      font-size: inherit;
    }
  }

  & ul {
    list-style-type: disc;
    margin-bottom: 20px;
    margin-left: 18px;
  }
}

.is-mobile {
  .disclosure-modal-header {
    height: 10vh;
  }

  .disclosure-modal-footer {
    height: 10vh;
  }

  .disclosure-modal-content {
    height: 80vh;
    max-height: 80vh;
  }
}

@media print {
  // hide all elements on the page
  body * {
    visibility: hidden;
  }

  // show only the modal
  .m-fixed-modal {
    height: 100%;
    overflow: visible;
    position: absolute;

    .m-common-modal {
      height: 100%;
      overflow: visible;

      .m-common-modal--center-content {
        top: 0;
        width: 100% !important;
      }
    }
  }

  // hide close button
  .disclosure-modal-close-button {
    display: none;
  }

  // hide footer
  .disclosure-modal-footer {
    display: none;
  }

  // remove styling from header
  .disclosure-modal-header {
    border: 0;
    overflow: visible;
    visibility: visible;

    & * {
      overflow: visible;
      visibility: visible;
      width: 100%;
    }
  }

  // make content fully visible
  .disclosure-modal-content {
    height: 100%;
    max-height: none;
    max-width: none;
    overflow: visible;
    visibility: visible;

    & * {
      overflow: visible;
      visibility: visible;
      width: 100%;
    }
  }
}
