.admin-settings-integration-row {
  .admin-settings-row__col--title {
    min-width: 200px;
    margin-top: -1px;
  }

  .admin-settings-row__title {
    display: flex;
    align-items: center;
  }

  .admin-settings-integration-row__title {
    font-weight: inherit;
  }

  .admin-setting__col--lock-select {
    text-align: right;
  }
}

.admin-settings-integration-row__icon {
  line-height: 0;
  margin-top: -2px;

  svg {
    margin: 0;
    margin-right: 0.5em;
  }
}
