.draw {
  width: 100%;
}

.canvasContainer {
  width: 100%;
  height: 180px;
  position: relative;
  overflow: hidden;
  border: 1px solid var(--dig-color__border__base);
}

.canvas {
  display: block;
  position: relative;
  z-index: 2;
}

.buttons {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: var(--spacing__unit--1_5);

  & > .button {
    padding: 0;
  }
}
