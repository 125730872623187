.m-embedded-testing-tool {
  font-weight: 400;

  p {
    font-size: 14px;
    font-weight: 400;
    float: none !important;
    line-height: 150%;

    a {
      font-weight: var(--type__body__standard--fontweight_strong);
    }
  }
  h3 {
    font-size: 16px;
    font-weight: var(--type__body__standard--fontweight_strong);
    margin-top: 40px;
    margin-bottom: 10px;
    letter-spacing: 0;
  }
  input[type='text'],
  input[type='url'],
  select {
    width: 500px;
    font-size: 14px;
    box-shadow: none;
  }
  input[type='checkbox'] {
    margin-right: 10px;
    background: white;
    border: 2px solid #ccc;
    position: relative;
    bottom: -3px;
  }
  label {
    font-size: 14px;
  }
  .help {
    width: 600px;
    word-break: keep-all;
    margin-bottom: 40px;
    p {
      font-size: 12px;
      margin-bottom: 10px;
      font-weight: 400;
      color: #666;
    }
  }
  hr {
    margin: 40px 0;
  }
  .or {
    float: left;
    width: 32px;
    height: 32px;
    font-size: 10px;
    font-weight: var(--type__body__standard--fontweight_strong);
    border: 2px solid #eee;
    border-radius: 50%;
    color: #ccc;
    text-align: center;
    padding: 10px 5px;
    margin: 3px 20px;
    line-height: 10px;
  }
  .get-sample-button {
    margin: 0;
  }
  code {
    font-size: inherit;
  }
}
