@import 'scss/colors.scss';

.m-ent-plan-summary {
  text-align: left;
}

.m-ent-plan-pricing-summary {
  width: 625px;
  height: 64px;
  /* Gray / 025 */
  background: $grey-025;
  border: 2px solid var(--dig-color__primary__base);
  box-sizing: border-box;
  margin-top: 32px;
}

.m-ent-plan-pricing-summary--pill {
  flex-direction: column;
  padding: 8px 12px;
  margin: 15px;
  width: auto;
  height: 32px;
  /* Blue / 600 */
  background: var(--dig-color__primary__base);
  border-radius: 23px;
  float: left;

  span {
    font-style: normal;
    font-weight: var(--type__body__standard--fontweight_strong);
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: $white;
    border-radius: 2px;
    flex: none;
    order: 0;
    align-self: center;
  }
}

.m-ent-plan-pricing-summary--users-price {
  height: 28px;
  font-style: normal;
  font-weight: var(--type__body__standard--fontweight_strong);
  font-size: 20px;
  line-height: 28px;
  color: $dark-black;
  float: left;
  margin: 15px 0;
}

.m-ent-plan-pricing-summary--users-currency {
  margin: 22px 0 22px 5px;
  height: 16px;
  font-style: normal;
  font-weight: var(--type__body__standard--fontweight_strong);
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 1px;
  text-transform: uppercase;
  vertical-align: middle;
  color: $dark-black;
  float: left;
}

.m-ent-plan-pricing-summary--users-price-description {
  margin: 22px 3px;
  height: 16px;
  float: left;
}

.m-ent-plan-pricing-summary--users-discount-amount {
  margin: 21px 20px 21px 20px;
  height: 16px;
  font-style: normal;
  font-weight: var(--type__body__standard--fontweight_strong);
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  float: left;
  color: $blue-600;
}

.m-ent-plan-pricing-summary--period-dates {
  text-align: right;
  height: 16px;
  font-style: normal;
  font-weight: var(--type__body__standard--fontweight_strong);
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $dark-black;
  margin: 21px 16px 21px 0;
  white-space: nowrap;
}

.m-ent-plan-pricing-additional-info {
  margin: 8px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  color: $color-text-mid;
}
