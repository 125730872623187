.survey {
  padding-top: var(--spacing__unit--1);
}

.otherRadioButton[type='radio'] {
  margin-bottom: 0;
}

.hideTooltip {
  display: none;
}

.header {
  margin: 0;
}

.contextContainer {
  margin-left: var(--spacing__unit--1);
  width: 100%;
}

.reasonContext {
  margin-top: var(--spacing__unit--1);
  margin-bottom: var(--spacing__unit--1);

  textarea {
    border: 1px solid var(--dig-TextInputContainer--border-color);

    &:hover {
      border: 1px solid var(--dig-TextInputContainer--border-color);
    }

    &:focus {
      background: none;
      border-color: var(--dig-TextInputContainer-focus-within--border-color);
      box-shadow: var(--dig-TextInputContainer-focus-within--box-shadow);
      outline: none;
    }
  }
}

.reasonGroup {
  display: flex;
}

.reasonList {
  margin-top: var(--spacing__unit--2);
}

.supportButton {
  margin-bottom: var(--spacing__unit--1);

  &:hover {
    margin-bottom: var(--spacing__unit--1);
  }
}

.otherContext {
  margin-bottom: 0;
}
