.loading {
  padding: 20px;
  flex-grow: 1;
}

.panel {
  padding-top: 3px;
}

.panelContent {
  padding: var(--spacing__unit--3) 3px;
  margin: -3px;
  height: 283px;
  overflow-y: auto;
}

/* Limited Viewport */
@media only screen and (max-width: 320px),
  only screen and (max-width: 900px) and (min-aspect-ratio: 3/2) {
  .modalBody {
    padding-left: var(--spacing__base_unit);
    padding-right: var(--spacing__base_unit);
  }

  .modalFooter.modalFooter {
    padding: var(--spacing__unit--0_5);

    .disclosure {
      margin-bottom: 0;
    }

    .insertButton {
      margin-top: var(--spacing__unit--0_5);
    }
  }

  :global(.dig-Modal.dig-Modal--fullScreen) .modalHeader.modalHeader {
    padding-top: var(--spacing__unit--0_5);
    padding-bottom: var(--spacing__base_unit);
  }
}

/* Mobile */
@media only screen and (max-width: 767px),
  only screen and (max-width: 900px) and (min-aspect-ratio: 3/2) {
  .modalBody {
    flex-basis: 0;
    overflow-y: auto;
    scroll-padding-top: calc(var(--spacing__unit--5) + var(--spacing__unit--3));
    /* tabs height + panel content top padding */
    scroll-padding-bottom: var(--spacing__unit--3);
  }

  .panelContent {
    height: unset;
  }

  .tabs {
    position: sticky;
    top: 0;
    z-index: 2;
    background: var(--dig-color__background__base);
    margin-top: -3px;
    padding-top: 3px;

    & > :global(.dig-Tabs-selector) {
      margin-top: 3px;
    }
  }

  .modalFooter.modalFooter {
    border-top: 1px solid var(--dig-color__border__subtle);
  }
}

/* Mobile Landscape */
@media only screen and (max-width: 900px) and (min-aspect-ratio: 3/2) {
  .tabs {
    /*  tabs shouldn't occupy the precious space in landscape */
    position: relative;
  }
}

.modalFooter.modalFooter {
  display: flex;
  justify-content: space-between;

  .buttonGroup {
    column-gap: var(--dig-spacing__micro__medium);
  }

  .insertButton {
    flex-shrink: 0;
  }
}

@media only screen and (max-width: 480px) {
  .modalFooter.modalFooter {
    flex-direction: column;

    .disclosure {
      margin-bottom: var(--spacing__base_unit);
    }

    .buttonGroup {
      width: 100%;
      flex-direction: column;
    }
    .insertButton {
      width: 100%;
      margin-top: var(--spacing__base_unit);
      margin-left: 0;
    }
  }
}
