.container {
  display: flex;
  flex-direction: column;
}

.header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1;

  :global(.dig-GlobalHeader) {
    width: 100%;
  }
}

.logo {
  &.isFax svg {
    max-height: 32px;
    vertical-align: middle;
  }
}

.content {
  display: flex;
  padding: var(--spacing__unit--1);
  width: 100%;
  min-width: 320px;

  &.centered {
    width: 100%;
    /* 824 + 8*2 (padding*2) */
    max-width: 840px;
    margin: 64px auto 48px;
    &.v900 {
      max-width: 900px;
      margin: var(--spacing__unit--5) auto;
    }

    @media (max-width: 1024px) {
      /* 396 + 16*2 (padding*2) */
      max-width: 424px;
      padding: 0 var(--spacing__unit--2);
      margin-top: 48px;
      &.v900 {
        margin-top: var(--spacing__unit--5);
      }
    }

    @media (max-width: 768px) {
      max-width: 396px;
      margin-top: 32px;
      &.v900 {
        margin-top: var(--spacing__unit--3);
      }
    }
  }
}

.contentInner {
  width: 100%;
}
