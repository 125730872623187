@import 'colors.scss';

.m-customer-cancel-offer {
  a {
    font-size: 17px;
    font-weight: 500;
  }

  .m-logo-only-header {
    margin-top: 30px;
  }

  .m-customer-cancel-offer--header-text {
    h1 {
      font-size: 54px;
      letter-spacing: 2px;
    }

    h2 {
      font-size: 18px;
      font-weight: 400;
    }
  }

  .m-customer-cancel-offer--main-content {
    margin-top: 50px;
  }

  .m-cancel-offer-plan-description {
    float: left;
    width: 60%;
    padding-right: 80px;
    padding-left: 40px;
    padding-top: 15px;
    font-weight: 500;

    .m-cancel-offer-plan-description--callout {
      font-size: 17px;
    }

    ul {
      margin: 30px 20px;

      li {
        list-style: initial;
        margin-bottom: 3px;
      }
    }
  }

  .m-cancel-offer-pricing-level {
    width: 40%;
    float: right;

    .m-cancel-offer-pricing-level--offer {
      background: $plume;
      border: 1px solid $warm-chinchilla;
      padding: 30px 40px;

      p {
        font-weight: 400;
        padding: 0 20px;
        margin-bottom: 8px;
      }

      .large-price {
        margin-top: -25px;

        .dollar {
          display: inline-block;
          font-size: 55px;
          font-weight: 500;
          color: var(--dig-color__primary__base);
          position: relative;
          top: -30px;
          left: 0;
        }

        .price {
          display: inline-block;
          font-size: 80pt;
          letter-spacing: -5px;
          font-weight: 200;
          color: var(--dig-color__primary__base);
        }
      }

      .previous-price {
        color: $cinder-block;

        .dollar {
          display: inline-block;
          position: relative;
          font-size: 18pt;
          top: -16px;
          left: 0;
        }

        .price {
          font-size: 50px;
          position: relative;
          font-weight: 300;

          &::before {
            position: absolute;
            content: '';
            left: -10px;
            top: 50%;
            right: -15px;
            border-top: 2px solid;
            border-color: red;
            -webkit-transform: rotate(-26deg);
            -moz-transform: rotate(-26deg);
            -ms-transform: rotate(-26deg);
            -o-transform: rotate(-26deg);
            transform: rotate(-26deg);
          }
        }
      }

      small {
        margin-top: -5px;
        font-size: 12px;
        color: $grey;
        text-transform: none;
      }

      .select-offer-button {
        padding: 15px 33px 14px 33px;
      }

      .round-or {
        color: $plume;
        background-color: $tepid-sloth;
        width: 30px;
        height: 30px;
        border-radius: 25px;
        font-size: 12px;
        font-weight: 500;
        padding-top: 7px;
        margin: 20px auto;
      }

      .continue-to-cancel-button {
        text-transform: none;
        padding: 14px 15px 13px 15px;
        font-size: 15px;
      }
    }
  }
}
