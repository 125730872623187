@import 'variables';

.m-pricing-arrow {
  @media #{$medium-only} {
    display: none;
  }
}

.arrow-button {
  width: 50px;
  cursor: pointer;
  cursor: hand;
}

.arrow-button-right {
  position: absolute;
  z-index: 100;
  transform: translate(-168px, 400px);
}

.arrow-button-left {
  position: absolute;
  z-index: 100;
  transform: translate(-1025px, 400px) rotate(180deg);
}

.arrow-line-right {
  border-left: 2px solid #e2e2e2;
  height: 641px;
  position: absolute;
  z-index: 99;
  transform: translate(855px, 60px);
}

.arrow-line-left {
  border-left: 2px solid #e2e2e2;
  height: 641px;
  position: absolute;
  z-index: 99;
  transform: translate(0px, 60px);
}
