.billboardImage {
  background: var(--color__core__secondary);
}

.header {
  margin: 0;
}

.benefitList {
  margin: var(--spacing__unit--2) 0 0 var(--spacing__unit--1);
}

.benefit {
  display: flex;
  margin-top: var(--spacing__unit--1);
}

.benefitIcon {
  margin-right: var(--spacing__unit--0_5);
}

.disclaimer {
  margin-top: var(--spacing__unit--2);
}
