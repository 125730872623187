:local {
  // An intermediate layer has this container and it'll be a little cleaner to
  // just hack it here instead of conditionally removing it.
  :global(.m-google-signin-button),
  .signInButtons {
    display: flex;
    flex-direction: column;
    gap: var(--spacing__unit--2);
    align-items: stretch;
  }
}
