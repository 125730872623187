.footerContainer {
  display: flex;
  margin-bottom: 1rem;

  justify-content: space-between;
  flex-direction: row;

  .responsive {
    flex-direction: 'column';
    justify-content: unset;
  }
}

.footer {
  display: flex;
  text-align: left;
  flex-direction: row;
  align-items: center;
}
.responsive .footer {
  text-align: center;
  flex-direction: 'column';
}

.responsive .footerLogo {
  padding-top: 1rem;
  padding-left: 1rem;
}

.footerLinks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.linkContainer {
  padding: 1rem;

  a {
    color: grey;

    &:hover {
      color: #4e4e4e; /* ashen */
    }
  }
}

.footerSocial {
  display: flex;
  justify-content: center;

  .socialIcon {
    margin-left: 0.25rem;
    padding-left: 0;
    padding-right: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;

    a {
      display: block;
    }
    a img {
      vertical-align: middle;
    }
  }
}

.menuItem {
  display: block;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: left;
}
