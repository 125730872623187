@import 'colors';

.dropbox-signin-button__button {
  background-color: $dropbox-blue;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: 1px;
  width: 215px;
  border: 1px inset transparent;
  display: block;
  font-size: 13px;
  padding: 0;
  margin: 0 auto;
  margin-bottom: 48px;
  transition:
    background-color 0.218s ease,
    box-shadow 0.218s ease;

  &:focus {
    background-color: $dropbox-blue;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    outline: none;
  }

  &:hover {
    background-color: $dropbox-blue;
    box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
  }

  .dropbox-signin-button--is-processing & {
    cursor: not-allowed;
    background-color: $warm-chinchilla;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  }
}

.dropbox-signin-button__left {
  float: left;
  width: 34px;
  height: 34px;
  background-color: white;
  position: relative;
  border-radius: 1px;
}

.dropbox-signin-button__right {
  display: inline;
  width: 100%;
  line-height: 34px;
  margin-left: 6px;
  margin-right: 6px;
  vertical-align: top;
  letter-spacing: 0.21px;
}

.dropbox-signin-button__icon {
  padding: 8px;
  box-sizing: content-box;
  width: 18px;
  height: 18px;
}

.dropbox-signin-button__error {
  color: $blood;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}
