@import 'variables';

.m-white-labeling {
  header {
    margin-top: 50px;
    text-align: center;
  }

  main {
    margin-top: 50px;

    .m-white-labeling-copy {
      border-top: 1px solid $warm-chinchilla;

      .m-white-labeling-copy-text {
        max-width: 1200px;
        margin-top: 50px;
        padding: 0 30px;
        margin-left: auto;
        margin-right: auto;

        p {
          margin-bottom: 10px;
        }
      }
    }
  }
}
