@import 'colors';

.admin-settings-row {
  padding: 16px 0;
  display: flex;
  line-height: 20px;

  &--is-primary {
    .admin-settings-row__title p {
      color: $ashen;
      font-weight: var(--type__body__standard--fontweight_strong);
    }
  }
}

.admin-settings-row__col {
  padding: 0 12px;

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;
  }

  &--title {
    min-width: 175px;
    max-width: 175px;
  }

  &--body {
    flex-grow: 1;
  }
}

.admin-settings-row__title {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: $color-text-dark;
  position: relative;
}

.admin-settings-row__body {
  font-size: 14px;

  .admin-setting {
    &:last-of-type {
      padding-bottom: 0;
    }
  }
}
