.dbxBillingContainer {
  padding-top: 32px;
}

.dbxBillingDetails {
  display: flex;

  > :first-child {
    margin-right: 8px;
  }

  > :last-child {
    padding-top: 5px;
  }
}
