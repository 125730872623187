@import 'colors';

._footer__logo {
  width: 32px;
}

._footer__links {
  font-size: 14px;
}

._footer__social {
  img {
    width: 24px;
    height: 24px;
  }
}

.m-app-footer--menu-item {
  white-space: nowrap;
  color: grey;
  :hover {
    color: $ashen;
  }
}
.m-app-footer--container {
  border-top: 1px solid $warm-chinchilla;
}
