.noSignatureContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    height: 200px;
    width: 200px;
  }

  p {
    margin: 0;
  }
}

.signatureGrid {
  width: 100%;
  margin: 0;

  tr {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--spacing__unit--2);
    margin-bottom: var(--spacing__unit--2);
  }
}

.signatureGridCell {
  cursor: pointer;
  padding: 2px;
  border: 1px solid var(--dig-color__border__subtle);
  outline: none;
  scroll-margin: 30px 8px 8px 8px;

  &.selected {
    padding: 0;
    border-width: 3px;
    border-color: var(--dig-color__secondary__base);
  }

  /*
     relies on "focus-visible" polyfill already used by DIG
     (see https://www.npmjs.com/package/focus-visible for more info)
  */
  &:focus[data-focus-visible-added] {
    box-shadow: var(--dig-utilities__boxshadow__focus-ring);
  }

  &:hover:not(.selected) {
    background-color: var(--dig-color__opacity__surface);
  }

  height: 120px;
}

@media only screen and (max-width: 767px),
  only screen and (max-width: 900px) and (min-aspect-ratio: 3/2) {
  .signatureGridCell {
    height: 100px;
  }

  .signatureGrid tr {
    grid-template-columns: 100%;
    gap: var(--spacing__unit--1);
    margin-bottom: var(--spacing__unit--1);
  }
}
