.content {
  --arrow-height: 9px;
  --color-muted: #e6e6e6;

  background: white;
  border-radius: 2px;
  border: 1px solid #e6e6e6;
  position: absolute;
  min-width: 120px;
  z-index: 1000;

  &:after {
    content: ' ';
    border-style: solid;
    border-color: transparent;
    border-width: calc(var(--arrow-height) - 2px);
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:before {
    border-style: solid;
    border-color: transparent;
    border-width: var(--arrow-height);
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  /* positionRule */
  &.bottom {
    top: 100%;
    margin-top: var(--arrow-height);
    &:before {
      bottom: 100%;
      margin-top: var(--arrow-height);
      margin-left: calc(0 - var(--arrow-height));
      border-bottom-color: var(--color-muted);
    }
    &:after {
      bottom: 100%;
      margin-left: calc(2 - var(--arrow-height));
      border-bottom-color: white;
    }
  }
  &.top {
    bottom: 100%;
    margin-bottom: var(--arrow-height);
    &:before {
      top: 100%;
      margin-bottom: var(--arrow-height);
      margin-left: calc(0 - var(--arrow-height));
      border-top-color: var(--color-muted);
    }
    &:after {
      top: 100%;
      margin-left: calc(2 - var(--arrow-height));
      border-top-color: white;
    }
  }

  &.left {
    right: 100%;
    margin-right: var(--arrow-height);
    &:before {
      left: 100%;
      margin-right: var(--arrow-height);
      border-left-color: var(--color-muted);
    }
    &:after {
      left: 100%;
      border-left-color: white;
    }
  }
  &.right {
    left: 100%;
    margin-left: var(--arrow-height);
    &:before {
      right: 100%;
      margin-left: var(--arrow-height);
      border-right-color: var(--color-muted);
    }
    &:after {
      right: 100%;
      margin-left: white;
      border-right-color: white;
    }
  }

  &.top,
  &.bottom {
    &.justifyLeft {
      left: 0;
      &:before {
        left: 20%;
      }
      &:after {
        left: 20%;
      }
    }
    &.justifyRight {
      right: 0;
      &:before {
        left: 80%;
      }
      &:after {
        left: 80%;
      }
    }
    &.justifyCenter {
      left: 50%;
      transform: translateX(-50%);
      &:before {
        left: 50%;
      }
      &:after {
        left: 50%;
      }
    }
  }
  &.left,
  &.right {
    &.alignTop {
      top: 0;
      &:before {
        top: 0;
        margin-top: calc(2 - var(--arrow-height));
      }
      &:after {
        top: 0;
        margin-top: var(--arrow-height);
      }
    }
    &.alignCenter {
      top: 50%;
      transform: translateY(-50%);
      &:before {
        top: 50%;
        margin-top: calc(0 - var(--arrow-height));
      }
      &:after {
        top: 50%;
        margin-top: calc(2 - var(--arrow-height));
      }
    }
  }
}

.root {
  position: relative;
}
