.dropzone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 232px;
  border: 2px dashed var(--dig-color__border__base);

  & > .uploadButton {
    margin-top: var(--spacing__unit--3);
  }
}

@media only screen and (max-width: 767px),
  only screen and (max-width: 900px) and (min-aspect-ratio: 3/2) {
  .dropzone {
    text-align: center;
    padding: var(--spacing__unit--2);
  }
}
