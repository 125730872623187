@import 'variables';

@media #{$small-only} {
  body.legacy-foundation-modal-visible {
    overflow: hidden;
  }

  #payment_modal {
    position: fixed;
    left: 0 !important;
    top: 0 !important;
    overflow-y: scroll;
    box-shadow: none;
    height: 100%;
  }
}

.hello-modal__content--payment-form-modal {
  max-width: 1100px;
  .hello-modal__body {
    padding: 8px 10px 24px;
  }
}
