.header {
  padding: 1rem;

  p {
    font-size: 1.5rem;
    font-weight: bolder;
    text-align: center;
  }
}

.content {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.modalFooter {
  padding: 1.5rem;

  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .downoadAndPrint {
      margin-left: 0.5rem;
    }
  }
}
