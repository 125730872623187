.advanced-search {
  position: relative;
  width: min-content;
}

// I shouldn't have to target #searchInput at all, but I need it twice to
// override specificity of other CSS that is WAY TOO BROAD.
.advanced-search input.query#searchInput#searchInput {
  display: inline;
  // We need room for both the search icon on the left and the caret on the right
  padding: 4px 35px 4px 35px;
  // I don't know why Webpack's resolve.modules doesn't work here
  background: white
    url('../../../public/images/v2/modules/home/search_icon_@2x.png') 10px
    center no-repeat;
  background-size: 16px;
  height: 31px;
  margin-bottom: 15px;
  margin-right: 15px;
  width: 320px;
}

.advanced-search label {
  font-size: 0.875em;
  white-space: nowrap;
  font-weight: normal;
  padding-right: 24px;
}

.advanced-search td {
  vertical-align: middle;
}

.advanced-search__inputs {
  margin-bottom: 16px;
}

.advanced-search__inputs input:active,
.advanced-search__inputs input:focus,
.advanced-search__inputs input {
  border-radius: 2px;
}

.advanced-search #advanced-search__type-status {
  margin: 0 0 4px 5px;
  font-size: 1em;
  background-color: white;
  border-radius: 2px;
  width: 275px;
}

.advanced-search__toggle {
  position: absolute;
  right: 0;
  top: 12px;
  margin-right: 15px;
  width: 30px;
  text-align: center;
}

.advanced-search__toggle img {
  height: 12px;
}

.advanced-search__test-mode-label {
  padding-top: 12px;
}

.advanced-search__popover {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.35);
  border-radius: 2px;
  top: 42px;
  left: 6px;
  background: white;
  position: absolute;
  padding: 24px;
  z-index: 100;
}

// WARNING: Targeting anything outside the component is BAD PRACTICE. It makes
// this component less portable, you can't just use it anywhere you want. Using
// it in a new place might just break the component. If the app is restructured
// and someone removes or renames #manage or #filterArea, this component may
// mysteriously break. But I have to have this selector in order to override a
// generic style on `#manage #filterArea .search input`
#manage #filterArea .advanced-search .advanced-search__date,
// This is all that should be needed.
.advanced-search .advanced-search__date {
  display: inline-block;
  // input[type=text] forces this to 100% width instead of allowing it to
  // scale based on maxLength. 100px seems to work on my screen, but it's
  // probably the wrong way to do this.
  width: 100px;
}

// WARNING: Targeting anything outside the component is BAD PRACTICE (See above)
#manage #filterArea .advanced-search input#advanced-search__test-mode,
.advanced-search #advanced-search__test-mode {
  width: auto;
  height: auto;
}

.advanced-search__buttons {
  display: table;
  width: 100%;
  font-size: 0.875em;
}

.advanced-search__button-group {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
}

.advanced-search__button-group--left {
  text-align: left;
}

.advanced-search__button-group--right {
  text-align: right;
}

.advanced-search__button-group__button {
  margin: 0;
  // Because .l-app .m-button demands that buttons can't have margins
  margin-left: 15px !important;
}
