@import 'colors';

.admin-setting {
  display: flex;
  padding-bottom: 10px;

  &--is-offset-controls {
    .admin-setting__col {
      &--toggle,
      &--lock-select {
        // Arbitraty top-padding per design.
        padding-top: 33px;
      }
    }
  }

  &--is-arbitrary-design-offset {
    .admin-setting__col {
      &--content {
        // Arbitrary negative margin per design.
        margin-top: -6px;
      }
    }
  }
}

.admin-setting__col {
  padding: 0 12px;
  flex-direction: row;

  &:first-of-type {
    padding-left: 0;
  }

  &--content {
    flex-grow: 1;
  }

  &--toggle,
  &--lock-select {
    height: 0;
  }

  &--toggle {
    text-align: center;
    min-width: 150px;
    margin-top: 4px;
  }

  &--lock-select {
    min-width: 205px;
    max-width: 205px;
    margin-top: -6px;
  }
}

.admin-setting__content,
.admin-setting__content p {
  font-weight: normal;
  letter-spacing: 0;
}

.admin-setting__toggle,
input[type='checkbox'] + label.admin-setting__toggle,
input[type='radio'] + label.admin-setting__toggle {
  margin-left: 0;
}
