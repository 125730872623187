@import 'colors.scss';
@import '_text.scss';

:local {
  .switchBillingPeriodModalAgreeContainer {
    display: flex;
  }

  .marginTop {
    margin-top: 20px;
  }

  input[type='checkbox'] {
    margin: 3px;
    transform: scale(1.15);
  }

  .switchBillingPeriodModalAgreeLabel {
    // Have to do these for the billing settings page.
    font-size: 0.875rem;
    font-weight: normal;
  }
  .modal {
    .header,
    .body,
    .footer {
      background-color: $white;
    }
    .title {
      font-family: Sharp Grotesk DB Book;
    }
    .body > :first-child {
      margin: 0;
    }
    .body > * + * {
      margin-top: calc(var(--spacing__base_unit) * 2);
      margin-bottom: 0;
    }
    .modalIllustration {
      background-color: $grey-025;
      padding: 0 150px;
    }
    .link {
      color: $velvet-evening;
      font-weight: var(--type__body__standard--fontweight_strong);
    }
  }
  .switchBillingPeriodModalRefund {
    padding-top: var(--spacing__unit--1_5);
  }
}
