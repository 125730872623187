.buttonsDivider {
  display: inline-block;
  margin-left: var(--spacing__unit--2);
  margin-right: var(--spacing__unit--2);
  height: 28px;
  width: 1px;
  background: var(--dig-color__border__base);
  vertical-align: middle;
}

@media only screen and (max-width: 767px),
  only screen and (max-width: 900px) and (min-aspect-ratio: 3/2) {
  .buttonsDivider {
    margin-left: var(--spacing__base_unit);
    margin-right: var(--spacing__base_unit);
  }
}
