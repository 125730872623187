// The leading underscore in _text.scss make this a partial. If you don't extend
// any of these, it won't emit any CSS

// Font settings are based on this:
// https://www.figma.com/file/N7i3rROqbDjTqopXdE9KrSMC/Foundation-Components?node-id=992%3A218

$spacers: (
  'xs': 4px,
  'sm': 8px,
  'ms': 12px,
  'md': 16px,
  'lg': 24px,
  'xl': 32px,
  'xxl': 64px,
);

@function getSpacer($spacer) {
  @if (map-has-key($spacers, $spacer)) {
    @return map-get($spacers, $spacer);
  } @else {
    @error "#{$spacer} is not a valid spacer";
  }
}

%spacer-top-xs {
  padding-top: getSpacer('xs');
}
%spacer-top-sm {
  padding-top: getSpacer('sm');
}
%spacer-top-ms {
  padding-top: getSpacer('ms');
}
%spacer-top-md {
  padding-top: getSpacer('md');
}
%spacer-top-lg {
  padding-top: getSpacer('lg');
}
%spacer-top-xl {
  padding-top: getSpacer('xl');
}
%spacer-top-xxl {
  padding-top: getSpacer('xxl');
}

%spacer-right-xs {
  padding-right: getSpacer('xs');
}
%spacer-right-sm {
  padding-right: getSpacer('sm');
}
%spacer-right-ms {
  padding-right: getSpacer('ms');
}
%spacer-right-md {
  padding-right: getSpacer('md');
}
%spacer-right-lg {
  padding-right: getSpacer('lg');
}
%spacer-right-xl {
  padding-right: getSpacer('xl');
}
%spacer-right-xxl {
  padding-right: getSpacer('xxl');
}

%spacer-bottom-xs {
  padding-bottom: getSpacer('xs');
}
%spacer-bottom-sm {
  padding-bottom: getSpacer('sm');
}
%spacer-bottom-ms {
  padding-bottom: getSpacer('ms');
}
%spacer-bottom-md {
  padding-bottom: getSpacer('md');
}
%spacer-bottom-lg {
  padding-bottom: getSpacer('lg');
}
%spacer-bottom-xl {
  padding-bottom: getSpacer('xl');
}
%spacer-bottom-xxl {
  padding-bottom: getSpacer('xxl');
}

%spacer-left-xs {
  padding-left: getSpacer('xs');
}
%spacer-left-sm {
  padding-left: getSpacer('sm');
}
%spacer-left-ms {
  padding-left: getSpacer('ms');
}
%spacer-left-md {
  padding-left: getSpacer('md');
}
%spacer-left-lg {
  padding-left: getSpacer('lg');
}
%spacer-left-xl {
  padding-left: getSpacer('xl');
}
%spacer-left-xxl {
  padding-left: getSpacer('xxl');
}
