.m-fixed-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2048;

  .m-common-modal {
    overflow: scroll;
  }

  &.mobile {
    .m-common-modal--center-content {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin-left: 0%;
      min-width: 0;
      padding: 0;
      transform: none;
      -webkit-transform: none;
      -moz-transform: none;
      -ms-transform: none;
      -o-transform: none;
    }

    .m-collect-info-form--field-container,
    .m-collect-info-modal--header {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
