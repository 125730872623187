@import 'colors';

.l-new-modal.remove-enterprise-member {
  .l-new-modal--content {
    width: 90%;
    margin-left: auto;
    margin-right: auto;

    label,
    select {
      font-size: 15px;
      font-weight: 300;
      display: inline-block;
      vertical-align: middle;
    }

    label {
      margin-right: 3%;

      span.ellipsis {
        max-width: 105%;
        font-weight: var(--type__body__standard--fontweight_strong);
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: bottom;
      }
    }

    select {
      margin-top: 3%;
    }

    span.warning-msg {
      color: $red;
      font-size: 12px;
      line-height: 14px;
      display: block;
      margin-top: -10px;
    }

    span.strong {
      font-weight: var(--type__body__standard--fontweight_strong);
    }
  }
}
