:local {
  .copyText {
    display: flex;
  }

  .textCopiedMessage {
    color: var(--dig-color__success__base);
    margin-left: var(--spacing__base_unit);
  }
}
