.editArea {
  display: flex;
  flex-direction: column;
}

.previewImage {
  margin: 0 auto;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.buttons {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: var(--spacing__unit--1_5);

  & > .textButton {
    padding: 0;
  }
}

.contrastLabel {
  margin-right: var(--spacing__base_unit);
}

.decreaseContrast {
  margin-right: var(--spacing__base_unit);
}

.contrastValue {
  margin-right: var(--spacing__base_unit);
  width: var(--spacing__unit--4);
  text-align: center;
}

@media only screen and (max-width: 767px),
  only screen and (max-width: 900px) and (min-aspect-ratio: 3/2) {
  .contrastLabel {
    margin-right: var(--spacing__unit--0_5);
  }

  .decreaseContrast {
    margin-right: var(--spacing__unit--0_5);
  }

  .contrastValue {
    margin-right: var(--spacing__unit--0_5);
  }
}
