:local {
  .default-icon {
    height: 2em;
    margin: 0em;
  }
  .hwlogo {
    width: 13em;

    @media (min-width: 64em) {
      width: 12em;
    }
  }

  .hslogo {
    width: 6em;

    @media (min-width: 64em) {
      width: 6em;
    }
  }

  .hs-api-logo {
    width: 13em;

    @media (min-width: 64em) {
      width: 12em;
    }
  }

  .hflogo {
    width: 13em;

    @media (min-width: 64em) {
      width: 12em;
    }
  }

  .dropbox {
    width: 11em;

    @media (min-width: 64em) {
      width: 12em;
    }
  }

  .slack {
    width: 11em;

    @media (min-width: 64em) {
      width: 12em;
    }
  }

  .gmail {
    width: 11em;

    @media (min-width: 64em) {
      width: 12em;
    }
  }

  .googleDoc {
    width: 11em;

    @media (min-width: 64em) {
      width: 12em;
    }
  }

  .oracle {
    width: 11em;

    @media (min-width: 64em) {
      width: 12em;
    }
  }

  .salesforce {
    width: 11em;

    @media (min-width: 64em) {
      width: 12em;
    }
  }

  .fn-dropdown-social-links--icon {
    margin: 0em 1.4em 0em 0em;
    width: 1.3em;
    height: 1.3em;

    @media (min-width: 64em) {
      margin: 0em 1em 0em 0em;
      width: 1.1em;
      height: 1.1em;
    }
  }
}
