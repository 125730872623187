@import 'colors.scss';

.indeterminate-checkbox {
  display: inline-block;
  margin-left: 0.5rem;
  margin-right: 0.5rem;

  svg {
    color: var(--primary-blue);
  }
}

.indeterminate-checkbox label {
  // Override legacy CSS targeting `input[type=checkbox] + label`
  margin: 0 !important;
}

.indeterminate-checkbox input[type='checkbox'] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: -5000px;
}

:global(.dropboxIntegration) {
  .indeterminate-checkbox svg {
    color: $dropbox-dark-blue;
    width: 12px;
    height: 12px;
  }
}
