@import 'colors';
@import 'spacers';
@import '../../../hellospa/components/switch-billing-period-modal/index.scss';

.billing-settings {
  // Hard-coded width because this component currently
  // lives in a legacy area of the app that is not mobile
  // responsive. Hard-coding the width allows us to fit
  // the component within the area we want. width: 100% is
  // no good here.
  width: 900px;
}

.billing-settings__billing-plan.billing-settings__billing-plan {
  padding-bottom: 32px;

  &:last-of-type {
    padding-bottom: 16px !important;
  }
}

.billing-settings__plan-name.billing-settings__plan-name {
  font-size: 16px;
  margin-bottom: 10px;
  letter-spacing: 0;
  font-weight: var(--type__body__standard--fontweight_strong);
}

.billing-settings__col-button.billing-settings__col-button {
  margin-left: 10px;
}

.billing-settings__total-charge {
  margin-bottom: 10px;
}

.billing-settings__plan-disclosure-text {
  font-size: 80%;
}

.billing-settings__plan-manage-link {
  margin-left: 8px;
  color: var(--dig-color__primary__base);
}

.billing-settings__for-business-label {
  display: inline;
  font-weight: normal;
  font-size: var(--type__body__standard--fontsize);
  margin-left: getSpacer('sm');
}

.billing-settings__paused-text {
  color: #9b0032;
}
