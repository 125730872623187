.footer {
  margin-top: var(--spacing__unit--1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footerSection {
  display: flex;
  align-items: center;
}

.cta {
  margin-left: var(--spacing__unit--2);
}
