:local {
  .iconLink {
    display: inline-flex;
    align-items: center;
  }

  .icon {
    display: inline-flex;
    align-items: center;
    margin-right: calc(var(--spacing__base_unit) / 2);
  }
}
