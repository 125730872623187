.admin-settings-simple-row__tooltip {
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
  background-color: rgba(206, 212, 218, 0.6);
  border-radius: 100%;
  margin-bottom: -4px;
  margin-left: 4px;

  &:after {
    content: '?';
    position: absolute;
    top: -3px;
    left: 5px;
    color: white;
    font-size: 13px;
    font-weight: var(--type__body__standard--fontweight_strong);
    text-align: center;
  }
}

.admin-settings-simple-row__tooltip-content {
  display: none;
  position: absolute;
  top: 50%;
  left: 32px;
  width: 350px;
  padding: 10px 15px;
  text-align: center;
  color: white;
  background: black;
  border-radius: 4px;
  transform: translateY(-50%);
  z-index: 1;

  .admin-settings-simple-row__tooltip:hover & {
    display: block;
  }

  &:before {
    content: '';
    position: absolute;
    border: 10px solid transparent;
    left: -20px;
    top: 50%;
    transform: translateY(-50%);
    border-right-color: black;
  }
}
