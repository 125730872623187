/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * !!  WARNING: Do NOT edit this file!  !!
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 *
 * Foundation components are not intended to be edited to
 * satisfy specific use cases. If you would like to propose
 * a change to this component, please reach out to a
 * frontend engineer.
 *
 * @author Nathan Buchar <nathan.buchar@hellosign.com>
 */

@import 'colors';
@import 'text';
@import 'spacers';

:local {
  .body {
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0;
    padding: 0;
    margin: 0;

    @extend %text;

    a {
      color: var(--dig-color__primary__base);
      font-weight: inherit;
    }

    &.large {
      @extend %text-large;
    }

    &.tiny {
      @extend %text-tiny;

      color: $color-text-darker;
    }

    &.dim {
      @extend %text-dim;
    }

    &.action {
      @extend %text-action;
    }

    &.error {
      @extend %text-error;
    }
  }
}
