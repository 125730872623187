.flex {
  display: flex;
  align-items: center;

  &.end {
    justify-content: end;
  }
}

.header {
  padding: 2rem 1rem;

  .p {
    font-size: 2.5rem;
    font-weight: semi-bold;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 1rem;
  }
}

.p3 {
  padding: 1rem;
}

.mb4 {
  margin-bottom: 2rem;
}
.my2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.px3 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.label {
  font-weight: normal;
  font-size: 1.5rem;
}
.disabled {
  color: #a0a0a0;
}
.infoIcon {
  margin-right: 0.5rem;
}
.closeLink {
  color: grey;
  font-size: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
