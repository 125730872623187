@import 'colors';

.m-or-divider {
  width: 100%;
  margin: 40px 0;
  position: relative;
}
.m-or-divider--modal-google-login-border {
  width: 75%;
  position: absolute;
  left: 12%;
  bottom: -14px;
}
.m-or-divider--or {
  text-transform: uppercase;
  margin: 0 auto;
  display: block;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  border: 1px solid $warm-chinchilla;
  text-align: center;
  padding: 10px 0;
  font-size: 10px;
  position: relative;
  z-index: 1;
  background: $white;
  color: $grey;
}
.m-or-divider--rule {
  width: 100%;
  height: 1px;
  background: $warm-chinchilla;
  position: absolute;
  left: 0;
  top: 50%;
  z-index: 0;
}
.m-or-divider--inline-google-login-border {
  right: 0;
  position: absolute;
  height: 100%;
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
}
.m-or-divider--or-inline-google-login-border {
  background: #e0e0e0;
  color: $white;
  font-weight: var(--type__body__standard--fontweight_strong);
  top: 40%;
  left: 50%;
  @media only screen and (max-width: 1024px) {
    left: 0;
    top: 90%;
  }
}
.m-or-divider--inline-google-login-border--rule {
  width: 1px;
  height: 100%;
  background: $warm-chinchilla;
  position: absolute;
  right: 0;
  top: 0;
  @media only screen and (max-width: 1024px) {
    width: 40%;
    height: 1px;
    right: 30%;
    top: 98%;
  }
  @media only screen and (max-width: 640px) {
    left: 20%;
    width: 60%;
  }
  @media only screen and (max-width: 480px) {
    left: 12%;
    width: 76%;
  }
}
