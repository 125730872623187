.savedSignature {
  position: relative;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
}

.savedSignatureInner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: var(--spacing__unit--3);
  box-sizing: border-box;
}

.savedSignatureImage {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.5;
  transition: opacity 0.35s ease;

  .savedSignature.selected &,
  .savedSignature:hover & {
    opacity: 1;
  }
}

.savedSignatureDelete.savedSignatureDelete {
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  pointer-events: none;

  .savedSignature.selected &,
  .savedSignature:hover & {
    display: block;
    pointer-events: all;
  }
}

@media only screen and (max-width: 767px),
  only screen and (max-width: 900px) and (min-aspect-ratio: 3/2) {
  .savedSignatureInner {
    padding: var(--spacing__unit--2_5);
  }
}
