/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * !!  WARNING: Do NOT edit this file!  !!
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 *
 * Foundation components are not intended to be edited to
 * satisfy specific use cases. If this component does not
 * do what you need it to, please reach out to a frontend
 * engineer before creating a new component or making
 * unapproved changes to this file.
 *
 * @author Nathan Buchar <nathan.buchar@hellosign.com>
 */

@import 'colors';
@import 'fonts';
@import 'text';
@import 'spacers';

:local {
  $flag-diameter: 12px;

  .textTag {
    @extend %text;
    position: relative;
    display: inline-block;
    padding-left: $flag-diameter + getSpacer('xs');
    border-radius: 2px;

    // Borders currently disabled
    // &.withBorder {
    //   // Really wanted this to be a border, but if it were
    //   // the border can be seen outside the edges of the flag.
    //   box-shadow: inset 0px 0px 0px 1px $grey-200;
    //   padding-right: getSpacer('sm');
    // }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      height: $flag-diameter;
      width: $flag-diameter;
      border-radius: $flag-diameter;
      margin-top: $flag-diameter * -0.5;
    }

    &.neutral:before {
      background-color: $grey-400;
    }

    &.info:before {
      background-color: $blue-300;
    }

    &.success:before {
      background-color: $green-300;
    }

    &.warning:before {
      background-color: $yellow-300;
    }

    &.error:before {
      background-color: $red-300;
    }

    &.danger {
      color: var(--dig-color__alert__base);
      display: inline-flex;
      align-items: center;
      padding-left: 0;

      .dangerIcon {
        margin-left: -5px;
        padding: 2px;
      }

      &:before {
        content: '';
      }
    }
  }
}
