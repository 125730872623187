.embedded-testing-tool {
  position: relative;
}

.x-hellosign-embedded {
  z-index: 100; // Our CSS sucks
}

.x-hellosign-embedded__modal-close-button {
  padding: 0 !important; // Our CSS sucks
}
