@import 'colors';
@import 'variables';

.m-simple-gray-layout {
  background: white;
  color: black;
  width: 100%;
  min-height: 100%;
  padding-bottom: 20px;

  @media #{$medium-up} {
    background: $plume;
    padding-top: 40px;

    .m-logo {
      margin: 0 0 40px 0 !important;
    }
  }

  // Center the logo in the header, make sure it's an appropriate size for $small screens
  .m-logo {
    display: block;
    margin: 40px 0;
    text-align: center;

    img {
      height: 25px;
    }
  }

  .m-simple-gray-layout--card {
    background: white;
    color: black;
    width: 100%;
    padding: 10px;

    // For medium screens and wider, display content as a centered card
    @media #{$medium-up} {
      margin: 10px auto;
      padding: 65px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      max-width: 500px;
    }

    h2 {
      font-size: 28px;
      font-weight: 300;
      margin-bottom: 10px;
      margin-top: 0;
      text-align: center;
    }
  }
}
