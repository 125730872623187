.pricing-animation-left {
  .pricing-row-enter {
    display: none;
    overflow: hidden;
    &.pricing-row-enter-active {
      display: block;
      width: 855px;
      -webkit-animation: fadeInRight 240ms;
      -moz-animation: fadeInRight 240ms;
      -o-animation: fadeInRight 240ms;
      -ms-animation: fadeInRight 240ms;
      animation: fadeInRight 240ms;
      -webkit-animation-fill-mode: forwards;
      -moz-animation-fill-mode: forwards;
      -o-animation-fill-mode: forwards;
      -ms-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      -webkit-animation-fill-mode: forwards;
    }
  }

  .pricing-row-leave {
    position: relative;
    overflow: hidden;
    &.pricing-row-leave-active {
      position: static;
      -webkit-animation: fadeOutLeft 240ms;
      -moz-animation: fadeOutLeft 240ms;
      -o-animation: fadeOutLeft 240ms;
      -ms-animation: fadeOutLeft 240ms;
      animation: fadeOutLeft 240ms;
      -webkit-animation-fill-mode: forwards;
      -moz-animation-fill-mode: forwards;
      -o-animation-fill-mode: forwards;
      -ms-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      -webkit-animation-fill-mode: forwards;
    }
  }
}

.pricing-animation-right {
  .pricing-row-enter {
    display: none;
    overflow: hidden;
    &.pricing-row-enter-active {
      display: block;
      width: 855px;
      -webkit-animation: fadeInLeft 240ms;
      -moz-animation: fadeInLeft 240ms;
      -o-animation: fadeInLeft 240ms;
      -ms-animation: fadeInLeft 240ms;
      animation: fadeInLeft 240ms;
      -webkit-animation-fill-mode: forwards;
      -moz-animation-fill-mode: forwards;
      -o-animation-fill-mode: forwards;
      -ms-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      -webkit-animation-fill-mode: forwards;
    }
  }

  .pricing-row-leave {
    position: relative;
    overflow: hidden;
    &.pricing-row-leave-active {
      position: static;
      -webkit-animation: fadeOutRight 240ms;
      -moz-animation: fadeOutRigth 240ms;
      -o-animation: fadeOutRight 240ms;
      -ms-animation: fadeOutRight 240ms;
      animation: fadeOutRight 240ms;
      -webkit-animation-fill-mode: forwards;
      -moz-animation-fill-mode: forwards;
      -o-animation-fill-mode: forwards;
      -ms-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      -webkit-animation-fill-mode: forwards;
    }
  }
}

@keyframes fadeInRight {
  0% {
    position: absolute;
    -webkit-transform: translateX(900px);
    -moz-transform: translateX(900px);
    -o-transform: translateX(900px);
    -ms-transform: translateX(900px);
    transform: translateX(900px);
  }

  100% {
    position: absolute;
    -webkit-transform: none;
    -moz-transform: none;
    -o-transform: none;
    -ms-transform: none;
    transform: none;
  }
}

@keyframes fadeInLeft {
  0% {
    position: absolute;
    -webkit-transform: translateX(-900px);
    -moz-transform: translateX(-900px);
    -o-transform: translateX(-900px);
    -ms-transform: translateX(-900px);
    transform: translateX(-900px);
  }

  100% {
    position: absolute;
    -webkit-transform: none;
    -moz-transform: none;
    -o-transform: none;
    -ms-transform: none;
    transform: none;
  }
}

@keyframes fadeOutLeft {
  0% {
  }

  100% {
    -webkit-transform: translateX(-900px);
    -moz-transform: translateX(-900px);
    -o-transform: translateX(-900px);
    -ms-transform: translateX(-900px);
    transform: translate(-900px);
  }
}

@keyframes fadeOutRight {
  0% {
  }

  100% {
    -webkit-transform: translateX(900px);
    -moz-transform: translateX(900px);
    -o-transform: translateX(900px);
    -ms-transform: translateX(900px);
    transform: translate(900px);
  }
}
