.wrapper {
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
  height: 90vh;
}

.viewSelectorRow {
  display: flex;
  gap: var(--spacing__base_unit);
  flex-wrap: wrap;
}

.accordionWrapper {
  border-right: 1px solid var(--dig-color__border__base);
  border-left: 1px solid var(--dig-color__border__base);
  background-color: white;
}

.accordion {
  button {
    // reset hellosign styles
    margin-bottom: 0;
    &:hover,
    &:active,
    &:focus {
      background-color: var(--dig-color__background__base);
    }
  }
}

.accordionItem {
  width: 350px;
  border: none;
  &:last-child {
    border-bottom: 1px solid var(--dig-color__border__base);
  }
}

.colorPickerRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing__base_unit);
}

.colorPicker {
  width: 2rem;
  flex-shrink: 0;
}

.previewComponentWrapper {
  padding: var(--spacing__base_unit);
  background-color: var(--dig-color__opacity__surface);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.previewHeader {
  padding: var(--spacing__base_unit);
  border-bottom: 1px solid var(--dig-color__border__base);
}

.preview {
  border: 1px solid var(--dig-color__border__base);
  border-left: none;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.signerIframe {
  height: 100%;
  width: 35vw;
}
