@import 'colors';

.mocked-google-signin-button,
.m-google-signin-button {
  margin: 0;

  // Inner div created by gapi, represents the actual button
  & > div {
    margin: 0 auto;

    // Placeholder for when button hasn't loaded or failed, should imitate a disabled button
    &.mocked-google-signin-button--placeholder {
      background: $warm-chinchilla;
      border-radius: 2px;
      color: $grey;
      cursor: not-allowed;
      display: block;
      font-size: 14px;
      height: 36px;
      padding: 12px 12px 10px 12px;
      text-align: center;
      width: 215px;
    }
  }
}

.m-log-in-form .m-google-signin-button {
  text-align: center;
}

.google-signin-button__button {
  background-color: $dropbox-blue;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: 1px;
  width: 215px;
  border: 1px inset transparent;
  display: block;
  font-size: 13px;
  padding: 0;
  margin: 0 auto;
  transition:
    background-color 0.218s ease,
    box-shadow 0.218s ease;

  &:focus {
    background-color: $dropbox-blue;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    outline: none;
  }

  &:hover {
    background-color: $dropbox-blue;
    box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
  }
}

.google-signin-button__left {
  float: left;
  width: 34px;
  height: 34px;
  background-color: white;
  position: relative;
  border-radius: 1px;
}

.google-signin-button__right {
  display: inline;
  line-height: 34px;
  margin-left: 6px;
  margin-right: 6px;
  vertical-align: top;
  letter-spacing: 0.21px;
}

.google-signin-button__icon {
  padding: 8px;
  box-sizing: content-box;
  width: 18px;
  height: 18px;
}

.google-signin-button-new_wrapper {
  display: flex;
  justify-content: center;
}
