@import 'colors';

@media print {
  // remove min-width
  .l-app {
    .l-app--canvas {
      min-width: 0;
    }
  }

  // remove some elements for better printing
  .m-settings--section {
    display: none;

    &.esign-disclosure {
      display: block;
    }
  }

  .m-settings--action,
  .l-app--footer,
  .l-app--header,
  .l-app--banner,
  #footer-fill-empty-space {
    display: none;
  }
}
