.team-page {
  & .edit-team-title-form {
    & input {
      width: 265px;
      height: 43px !important;
      display: inline-block;
    }
    & button {
      margin-left: 6px !important;
      height: 42px;
    }
    margin-bottom: 13px;
  }
}

#terms-container {
  display: flex;
}

#terms-checkbox-label {
  margin-left: var(--spacing__base_unit);
}
