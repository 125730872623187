.lineDivider {
  border-style: solid;
  border-color: var(--dig-color__border__base);
  border-width: 0 0 1px 0;

  &.faint {
    border-color: var(--dig-color__border__subtle);
  }
  &.vertical {
    border-width: 0 1px 0 0;
  }
}
