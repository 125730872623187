:local {
  $size: 1em;
  $width: calc(#{$size} * 2);
  $center: calc(calc(#{$width} - #{$size}) / 2);

  .checkbox {
    display: none;
  }

  .checkbox + .label {
    display: inline-block;
    width: $width;
    height: $size;
    border-radius: $size;
    background-color: #959ca4;
    outline: 0;
    cursor: pointer;
    transition: background-color 0.09s ease-in-out;
    position: relative;
    background-position: 0em 0em;
    background-size: 2em;
    font-size: inherit;
  }

  .checkbox:disabled + .label {
    background-color: rgba(155, 155, 155, 0.5);
    cursor: not-allowed;
  }

  .checkbox:checked + .label {
    background-color: var(--dig-Button-primary--background-color);
  }

  .checkbox:disabled:checked + .label {
    background-color: rgba(0, 133, 171, 0.5);
  }

  .checkbox + .label:after {
    content: '';
    width: $size;
    height: $size;
    background: white center no-repeat;
    border-radius: $size;
    position: absolute;
    transform: scale(0.7);
    left: 0;
    transition: left 0.09s ease-in-out;
  }

  .checkbox:not(:checked) + .label {
    background-image: url('./assets/input-switch-off.svg');
    background-position: center;
  }

  .checkbox:checked + .label {
    background-image: url('./assets/input-switch-on.svg');
    &:after {
      left: $size;
    }
  }

  .checkbox:checked + .label.indeterminate {
    background-image: none;
    &:after {
      left: $center;
      background-image: url('./assets/input-switch-indeterminate.svg');
      background-size: 200%;
    }
  }
}
