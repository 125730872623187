:local {
  .logo svg {
    max-height: 32px;
    vertical-align: middle;
  }

  .upgradeCta {
    line-height: 30px;
  }

  // Disable problematic legacy HelloSign global styles.
  :global(.dig-GlobalHeader-Search) {
    svg {
      height: unset;
    }
  }

  // Disable problematic legacy HelloSign global styles.
  :global(input.dig-GlobalHeader-Search__input) {
    margin: 0;
    letter-spacing: 0;
    box-shadow: none;
    transition: none;

    svg {
      height: unset;
    }
  }
}
