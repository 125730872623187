@import 'colors.scss';

.m-collect-info-modal {
  padding-top: 60px;

  h1 {
    text-align: center;
    font-size: 30px;
    font-weight: 300;
    letter-spacing: 0.05em;
    line-height: 1.2em;
  }

  small {
    font-weight: 300;
  }

  form {
    margin-bottom: 60px;

    .row {
      margin-bottom: 20px;
    }

    label {
      font-weight: 300;
      font-size: 1em;
      margin-bottom: -6px;

      .asterisk {
        color: $red;
      }
    }

    input,
    select {
      margin-top: 9px;
      height: 45px;

      &.error {
        border-color: $red;
      }
    }

    select {
      font-weight: 300;
      font-size: 16px;
    }

    .error > p {
      font-size: 12px;
      color: $red;
    }
  }

  .cta-contact-fields {
    display: inline-block;
    min-width: 410px;
    margin: 20px auto;

    &::before {
      content: '';
      width: 100%;
      margin-bottom: 30px;
      display: inline-block;
      border-top: 1px solid $warm-chinchilla;
    }

    .line-deco {
      position: absolute;
      width: 5px;
      height: 56px;
      bottom: 116px;
      border: 1px solid $warm-chinchilla;
      border-right-color: transparent;
    }

    .contact-content-wrapper {
      height: '100%';
      width: '100%';
      margin-left: 10px;
    }

    .contact-me-and-number,
    .how-many-users-area {
      & > * {
        display: inline-block;
      }

      & > p {
        letter-spacing: 0px;
      }
    }

    .contact-me-and-number {
      input[type='checkbox'] {
        height: 12px;
      }

      p {
        margin: 0 5px;
      }

      input[type='text'] {
        max-width: 150px;
        background-color: $white;
        border-color: transparent;
        border-bottom-color: $light-grey;
        height: 20px;
        position: absolute;

        &.error {
          color: $red;
        }
      }
    }

    .phone-error,
    .num-users-error {
      position: absolute;
      font-size: 12px;
      color: $red;
      left: 130px;
      font-weight: 400;
    }

    .phone-error {
      bottom: 145px;
    }

    .num-users-error {
      bottom: 80px;
    }

    .how-many-users-area {
      p {
        margin: 0 10px 0 0;
      }

      select {
        max-width: 140px;
      }
    }
  }

  .cta-bar {
    background-color: $plume;
    padding: 12px 30px;
    min-height: 60px;
  }

  button {
    margin: 12px 30px;
    float: right;
  }

  .subheader {
    margin-top: 5px;
    margin-bottom: 30px;
    text-align: center;
  }

  .m-collect-info-form--field-container,
  .m-collect-info-modal--header {
    padding-left: 100px;
    padding-right: 100px;
  }
}
