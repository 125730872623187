.levels {
  padding-left: 12px;
  @media (max-width: 1023px) {
    padding-left: 0px;
  }
}

.toggle-container {
  padding-left: 0px;
}

.pp-api-pricing-text {
  padding-left: 0px;
  clear: both;
  @media (max-width: 640px) {
    img {
      margin-top: -50px;
    }
    .blurb-container {
      max-width: 300px;
    }

    .blurb-text {
      padding-bottom: 4px;
    }
  }

  @media (min-width: 641px) {
    .blurb-text {
      float: left;
      padding-right: 4px;
    }

    .blurb-link {
      float: right;
    }

    img {
      margin-top: -8px;
    }
  }
}

.pp-api-documentation-text {
  padding-left: 0px;
  clear: both;
  @media (max-width: 640px) {
    img {
      margin-top: -90px;
    }
    .blurb-container {
      width: 300px;
    }

    .blurb-text {
      padding-bottom: 6px;
      float: none;
    }

    .blurb-link {
      float: none;
    }
  }

  @media (min-width: 641px) {
    .blurb-text {
      float: left;
      padding-right: 4px;
    }

    .blurb-link {
      float: right;
    }

    img {
      margin-top: -8px;
    }
  }
}
