.signatureModalLine {
  position: absolute;
  z-index: 0;
  border-bottom: 1px solid var(--dig-color__secondary__base);
  left: var(--spacing__unit--3);
  right: var(--spacing__unit--3);
  bottom: var(--spacing__unit--4);

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: var(--spacing__unit--2);
    bottom: var(--spacing__base_unit);
    width: 1.5px;
    height: var(--spacing__unit--2);
    background-color: var(--dig-color__text__base);
  }

  &:before {
    transform: rotateZ(45deg);
  }

  &:after {
    transform: rotateZ(-45deg);
  }
}

@media only screen and (max-width: 767px),
  only screen and (max-width: 900px) and (min-aspect-ratio: 3/2) {
  .signatureModalLine {
    left: var(--spacing__unit--2);
    right: var(--spacing__unit--2);
  }
}

/* Mobile landscape */
@media only screen and (max-width: 900px) and (min-aspect-ratio: 3/2) {
  .signatureModalLine {
    bottom: var(--spacing__unit--2);
  }
}
