:local {
  .mt-3 {
    margin-top: 1rem;
  }

  .mt-5 {
    margin-top: 3rem;
  }

  .inline {
    display: inline;
  }
}
