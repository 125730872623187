.countries-dropdown--select {
  width: 110px;
  direction: ltr;
  padding: 10px;
  border: 0;
  font-size: 13px;
  color: #161d25;
  background-color: #fff;
  -webkit-box-shadow: unset;
  box-shadow: unset;
}
