.logo.logo {
  color: black;
  height: 32px;

  &.tagline {
    height: 40px;
  }
}

.logo:focus-within svg {
  box-shadow: var(--dig-utilities__boxshadow__focus-ring);
  outline: 0;
  text-decoration: none;
}

.logoLink {
  display: block;
}
