.m-common-modal {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;

  .m-common-modal--center-content {
    position: relative;
    top: 200px;
    width: 100%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    min-width: 400px;
  }
}
