@import 'spacers';

:local {
  .globalNav {
    padding: getSpacer('lg') getSpacer('xl');
    display: flex;
    flex-direction: column;
    height: calc(100vh - 49px); // 49px is the height of the header
    // workaround for the mobile safari viewport bug (claimed by Apple to be a feature, not a bug)
    // https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/
    // https://nicolas-hoizey.com/articles/2015/02/18/viewport-height-is-taller-than-the-visible-part-of-the-document-in-some-mobile-browsers/
    max-height: -webkit-fill-available;
    overflow: auto;
    min-width: 240px;
    line-height: 16px;
  }

  .actionButtons {
    display: flex;
    flex-direction: column;
    padding-bottom: getSpacer('xl');

    > *:not(:first-child) {
      margin-top: getSpacer('sm'); // 8px
    }
  }

  .filtersList {
    padding: getSpacer('sm') 0;
    padding-left: getSpacer('ms');
  }

  .filter {
    padding: getSpacer('xs') 0;
  }

  .formsLink {
    padding-top: 3rem; // Fallback for non-spa pages where `pt-5` doesn't work
  }

  .navItems {
    overflow: auto;
  }

  .templateNavSubItems + .navItem,
  .navItem + .templateNavSubItems,
  .navItem + .navItem {
    padding-top: getSpacer('md'); // 16px
  }

  .navItemText,
  .filterText {
    position: relative;
    color: var(--dig-color__text__subtle);
    display: flex;
  }

  .navItem,
  .filter {
    &.active,
    a:hover {
      .navItemText,
      .filterText {
        color: var(--dig-color__text__base);
      }
    }
  }

  .navButton {
    // Allow button to wrap onto multiple lines if it
    // overflows container with static width.
    white-space: normal;
    text-align: left;

    // Increase specificity to override standard DIG button
    // height to allow the text to wrap.
    &.navButton {
      height: auto;
    }

    &:hover {
      text-decoration: underline;

      :global(.dig-Text) {
        color: var(--dig-color__text__base);
      }
    }
  }

  .navItem a {
    display: block;
  }

  .navItem,
  .footerItem {
    a:hover {
      text-decoration: underline;

      :global(.dig-Text) {
        color: var(--dig-color__text__base);
      }
    }

    a:focus :global(.dig-Text) {
      box-shadow: var(--dig-utilities__boxshadow__focus-ring);
    }
  }

  .footerItems {
    padding-top: getSpacer('ms');
    margin-top: auto;
    max-width: 240px - (32 * 2); // Sidebar width minus padding

    svg {
      color: var(--dig-color__text__subtle);

      &:hover {
        color: var(--dig-color__text__base);
      }
    }
  }

  .footerItem {
    :global(.dig-Menu),
    :global(.dig-ClickOutside) {
      display: block;
    }
  }

  .footerItem + .footerItem {
    padding-top: getSpacer('xs');
  }

  .betaTag {
    margin-left: getSpacer('xs');
  }

  .faxLines {
    margin-top: getSpacer('xxl');
    background-color: var(--dig-color__background__subtle);
    padding: getSpacer('ms') getSpacer('md');
    box-shadow: 0px 0px 0px 1px var(--dig-color__border__base) inset;
  }

  .bulkSendModalHero {
    background-color: var(--dig-color__secondary__surface);
  }

  .bulkSendTooltipSteps {
    display: flex;
    justify-content: space-between;
  }

  .templateNavItem {
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
  }

  .templateNavSubItems {
    text-indent: 15px;
  }
}
