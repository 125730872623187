.main {
  text-align: center;
  flex: 1;
}

.wordmarkBox {
  padding-bottom: 4rem;
  padding-top: 4rem;
}

.fileIcon {
  margin: 0.5rem;
}

.heading {
  margin-bottom: 2rem;
  font-size: 26px;
  font-weight: bold;
}

.back {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
