.form {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  white-space: nowrap;

  .headerTitle,
  .headerAside {
    margin-bottom: var(--spacing__unit--1);
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;

    .headerAside {
      margin: 0;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
}

.centered {
  align-self: center;
}

.footer {
  margin-top: var(--spacing__unit--4);
  margin-bottom: var(--spacing__unit--2);
}

.countryCode {
  text-align: right;
  display: block;
}

.email {
  margin-top: var(--spacing__unit--1);
}

.buttons {
  display: flex;
  flex-direction: column;

  > *:not(:last-child) {
    margin-bottom: var(--spacing__unit--2);
  }
}

.banner {
  margin-bottom: var(--spacing__unit--4);
  margin-top: -72px; // Banner should not push down any content, per spec

  @media (max-width: 768px) {
    margin-top: 0;
  }
}

.checkboxGroup {
  display: flex;

  > * + * {
    margin-left: var(--spacing__base_unit);
  }
}

.editableInput {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: var(--spacing__base_unit);
  border: 1px solid transparent;
  gap: var(--spacing__unit--2);
}

.editableInputValue {
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.rememberMeAndContinueBtn {
  display: flex;
  justify-content: space-between;
  margin-top: var(--spacing__unit--1_5);
  gap: var(--spacing__unit--2);
}

.passwordInputContainer {
  position: relative;
}

.passwordError {
  display: flex;
  align-items: center;
}

// Needs high specificity to override .dig-FormLabel.
.disabledFormLabel.disabledFormLabel {
  color: var(--dig-color__text__subtle);
}

.disabledFormInput {
  cursor: not-allowed;
}

// Make space for visibility toggle icon.
// Needs high specificity.
.passwordInput.passwordInput {
  padding-right: var(--spacing__unit--5);
}

.formContent {
  max-width: 396px;
}

.centered {
  align-self: center;
}

.iHaveAnAccountFooter {
  text-align: center;
  margin-top: var(--spacing__unit--0_5);
}

// Visibily hide the password field, but it still
// needs to be usable for password managers.
.hidden {
  pointer-events: none;
  height: 0;
  overflow: hidden;
}

.flexColumnReverse {
  display: flex;
  flex-direction: column-reverse;
}
