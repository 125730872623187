@import 'colors.scss';

.m-settings--cancel {
  color: $castle-rock;

  h1 {
    font-size: 30px;
    font-weight: 300;
  }

  h2 {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  h3 {
    margin-top: 20px;
    font-size: 14px;
    font-weight: 500;
  }

  h3.m-cancel--subtitle {
    margin-top: 5px;
    margin-bottom: 15px;
  }

  h4 {
    font-size: 12px;
    font-weight: 500;
  }

  input,
  textarea {
    color: $castle-rock;
  }

  input[type='text'] {
    margin-top: 10px;
  }

  textarea {
    height: 100px;
    width: 80%;
    margin: 10px 0;
  }

  .m-settings--cancel--reminder {
    margin-bottom: 30px;

    li {
      font-weight: 400;
    }
  }

  .m-settings--cancel--actions {
    display: inline-block;
  }

  .m-settings--cancel--api-option {
    & > label {
      margin-right: 10px;
    }
  }
}

.m-settings--cancel--toggle-container {
  margin-top: 15px;

  & > input[type='radio'] {
    margin: 0;
  }

  & > label {
    font-size: 14px;
    font-weight: var(--type__body__standard--fontweight_strong);
    margin-bottom: 10px;
  }

  & > input[type='radio'] + label {
    font-size: 0.9em;
    font-weight: 400;
    margin-right: 15px;
  }
}

.m-settings--cancel--reason-container {
  clear: both;

  & > label {
    font-size: 14px;
    font-weight: var(--type__body__standard--fontweight_strong);
    margin-bottom: 10px;
  }
}

.m-settings--cancel--reason-list {
  margin-top: 10px;
}

.m-settings--cancel--reason-list--item {
  & > label {
    font-size: 0.8em;
    font-weight: 400;
  }

  & > input {
    margin: 0 0;
  }
}

.m-settings--cancel--competitor-info {
  width: 500px;
}

.m-settings--cancel--hr {
  clear: both;
  width: 80%;
  height: 5px;
  border-top: 1px solid $warm-chinchilla;
  margin-bottom: 30px;
}

.m-settings--cancel--competitor-autocomplete {
  border: 1px solid $light-grey;
  z-index: 500;
  padding: auto 5px;
}

.m-settings--cancel--competitor-autocomplete--item {
  padding: 0 15px;
  font-weight: 400;

  &:hover {
    background-color: $plume;
  }
}

.m-settings--cancel--error {
  color: $red;
  font-size: 14px;
  font-weight: 500;
}

.m-settings--cancel--plan-switch-container {
  clear: both;
}
