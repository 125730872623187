.embedded-testing-tool__form {
  float: left;
  width: 55%;
  box-sizing: border-box;
  min-width: 450px;
}

.embedded-testing-tool__autogenerate {
  text-align: center;
}

.embedded-testing-tool__autogenerate-or {
  font-size: 10px !important;
  font-weight: var(--type__body__standard--fontweight_strong);
  border: 2px solid #eee;
  border-radius: 50%;
  color: #ccc;
  text-align: center;
  padding: 10px;
  text-transform: uppercase;
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: 20px 0;
}

.embedded-testing-tool__autogenerate-reload-msg {
  padding: 20px 0;
}

.embedded-testing-tool__autogenerate-error-msg {
  padding: 20px 0;
  color: red;
}

.embedded-testing-tool__form-advanced {
  transition:
    max-height 0.5s ease,
    opacity 0.5s ease;
  will-change: max-height, opacity;
  opacity: 0;
  max-height: 0;
  overflow: hidden;

  &--shown {
    opacity: 1;
    max-height: 5000px; // increase if needed
  }
}

.embedded-testing-tool__form-white-labeling-code {
  resize: vertical !important;
  transition-property: all;

  &--invalid {
    border-color: red !important;
    background-color: rgba(255, 0, 0, 0.1) !important;
  }
}
