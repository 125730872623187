@import 'colors';

#fax_line_config {
  .search-header {
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 5px;
  }

  .search-fax-lines {
    margin-bottom: 25px;

    input {
      display: inline-block;
      float: left;
      height: 31px !important;
      margin-right: 5px !important;
      margin-top: 0 !important;
      width: 260px;
    }
    button {
      height: 31px !important;
      padding: 5px !important;

      .m-button--icon {
        height: 18px;
        width: 18px;
      }
    }
  }

  .search-footer-msg {
    color: $light-grey;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
  }
}
