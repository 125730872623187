@import 'colors';

/**
 * 1. Needed to make sure the entire modal has rounded corners.  The top two
 * corners are covered by the modal styles
 */

.team-reporting-modal-bar {
  background: $plume;
  border-radius: 0 0 5px 5px; /* [1] */
}

.team-reporting-modal-close-button {
  position: absolute;
  right: 12px;
  top: 12px;
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.team-reporting-modal__info-icon {
  width: 30px;
}
