@import 'colors';
@import '../../../common/scss/mixins';

:local {
  .checkbox-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;

    .checkbox-description {
      padding: 0 2rem;
      font-family: proxima-nova, sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      margin-bottom: 0;
      letter-spacing: 0.05em;
      line-height: 1.5em;
      color: #1a1a1a;
    }
  }

  .modal-title {
    font-size: 15px !important;
  }

  .divider {
    display: block !important;
  }

  .error {
    color: $red;
    font-size: 12px;
  }

  .delete-documents-modal-content {
    width: 900px;
    max-width: 900px;

    @include mobile {
      width: 100%;
    }
  }
}
