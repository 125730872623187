.orDivider {
  display: flex;
  align-items: center;

  .or {
    margin: var(--spacing__unit--2_5) var(--spacing__unit--1);
  }

  .divider {
    margin: 0;
    flex-grow: 1;
  }
}
